import React from "react"
import styled from "styled-components"

import GalleryItem from "./galleryItem"

const Gallery = ({ items }) => {
  return (
    <Container>
      {items.map((item, i) => (
        <GalleryItem area={i + 1} key={i} {...item} />
      ))}
    </Container>
  )
}

export default Gallery

const Container = styled.div`
  padding: 80px 0 40px 0;
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1080px) {
    grid-template-columns: 100%;
  }
`
