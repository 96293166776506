import React from "react"
import styled from "styled-components"
import Reveal, { Fade } from "react-awesome-reveal"

import HomeAwayItem from "./homeAwayItem"
import revealAnimation from "../../../styles/animations/revealAnimation"

const HomeAway = ({ title, text, items }) => {
  return (
    <Container id="experience">
      <Reveal keyframes={revealAnimation} triggerOnce>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
        <Text>{text.text}</Text>
      </Reveal>
      <ItemsContainer>
        {items.map((item, i) => (
          <Fade key={i} delay={(i + 1) * 200} triggerOnce>
            <HomeAwayItem {...item} />
          </Fade>
        ))}
      </ItemsContainer>
    </Container>
  )
}

export default HomeAway

const Container = styled.div`
  display: grid;
  place-items: center;
  width: 90%;
  padding: 80px 0;
  margin: 0 auto;
  @media (max-width: 440px) {
    width: 90%;
    padding: 80px 0;
  }
`
export const Title = styled.div`
  h2 {
    //max-width: 60%;
    color: var(--primary-text);
    font-size: 3.5rem;
    font-family: "Canela Light", sans-serif;
    text-align: center;
    margin: 2rem auto 2rem auto;
    @media (max-width: 1050px) {
      //max-width: 100%;
    }
    @media (max-width: 1050px) {
      font-size: 3rem;
    }
  }
`
const Text = styled.p`
  color: var(--primary-text);
  max-width: 550px;
  text-align: center;
  font-family: "Moderat Regular", sans-serif;
  line-height: 24px;
`
const ItemsContainer = styled.div`
  margin-top: 80px;
  width: 100%;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  justify-content: center;
  @media (max-width: 788px) {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
`
