import React, { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import BackgroundImage from "gatsby-background-image"

const HomeAwayItem = ({ image, text, title }) => {
  const [fadeIn, setFadeIn] = useState(false)
  return (
    <Container fluid={image.fluid}>
      <Title animationIn={fadeIn ? 1 : 0} style={{ paddingTop: "40px" }}>
        {title.text}
      </Title>
      <Overlay
        onMouseOver={() => setFadeIn(true)}
        onMouseLeave={() => setFadeIn(false)}
      >
        <Content animationIn={fadeIn ? 1 : 0}>
          <OverlayTitle>{title.text}</OverlayTitle>
          <Text>{text.text}</Text>
        </Content>
      </Overlay>
    </Container>
  )
}

export default HomeAwayItem

const fadeIn = keyframes`
  from  {opacity:0;}
  to  {opacity:1;}
`
const fadeOut = keyframes`
  from  {opacity:1;}
  to  {opacity:0;}
`

const Container = styled(BackgroundImage)`
  height: 600px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  @media (max-width: 1080px) {
    height: 400px;
  }
`
const Overlay = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 0.5s ease-in-out;
  :hover {
    transition: background 0.5s ease-out;
    background: rgba(46, 46, 46, 0.9);
  }
`

const Content = styled.div<{ animationIn: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 32px;
  align-items: center;
  text-align: center;
  animation: ${({ animationIn }) =>
    animationIn
      ? css`
          ${fadeIn} 0.5s forwards;
        `
      : css`
          ${fadeOut} 0.5s forwards;
        `};
`
const Title = styled.h3<{ animationIn?: number }>`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-white);
  font-size: 24px;
  text-align: center;
  align-self: flex-end;
  padding: 16px 8px 16px 16px;
  animation: ${({ animationIn }) =>
    !animationIn
      ? css`
          ${fadeIn} 0.5s forwards;
        `
      : css`
          ${fadeOut} 0.5s forwards;
        `};
`

const OverlayTitle = styled.h3<{ animationIn?: number }>`
  font-family: "Moderat Bold", sans-serif;
  color: var(--primary-white);
  font-size: 24px;
  text-align: center;
  align-self: center;
  padding: 16px 8px 16px 16px;
  animation: ${({ animationIn }) =>
    !animationIn
      ? css`
          ${fadeIn} 0.5s forwards;
        `
      : css`
          ${fadeOut} 0.5s forwards;
        `};
`
const Text = styled.p`
  font-family: "Moderat Medium", sans-serif;
  color: var(--primary-white);
  max-width: 300px;
  line-height: 24px;
`
