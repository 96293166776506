import React, { useState } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import Reveal from "react-awesome-reveal"
import { BsChevronRight, BsChevronLeft } from "react-icons/bs"

import {
  Button,
  ButtonContainer,
  Icon,
  Text,
  Title,
} from "./gallery/galleryItem"

import revealAnimation from "../../styles/animations/revealAnimation"

const Slider = ({ title, text, link, link_label, items }) => {
  const [current, setCurrent] = useState<number>(0)
  const end = items.length - 1

  const previous = () => {
    current === 0 ? setCurrent(end) : setCurrent(current - 1)
  }
  const next = () => {
    current === end ? setCurrent(0) : setCurrent(current + 1)
  }

  return (
    <Reveal keyframes={revealAnimation} triggerOnce>
      <Background fluid={items[current].image.fluid}>
        <Container>
          <StyledLink to={link.url}>
            <Overlay>
              {end > 0 ? <ArrowLeft onClick={previous} /> : null}
              <Content>
                <Title dangerouslySetInnerHTML={{ __html: title.html }} />
                <Text>{text.text}</Text>
                <ButtonContainer>
                  <Button>{link_label}</Button>
                  <Icon />
                </ButtonContainer>
              </Content>
              {end > 0 ? (
                <ImageNavContainer>
                  {items.map((_, i) => (
                    <ImageNav
                      key={i}
                      onClick={() => setCurrent(i)}
                      active={i === current}
                    />
                  ))}
                </ImageNavContainer>
              ) : null}
              {end > 0 ? <ArrowRight onClick={next} /> : null}
            </Overlay>
          </StyledLink>
        </Container>
      </Background>
    </Reveal>
  )
}

export default Slider

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  align-items: center;
  align-content: center;
  padding-left: 100px;
  transition: background 500ms ease-in-out;
  :hover {
    transition: background 500ms ease-out;
    background: rgba(0, 0, 0, 0.7);

    > div > div :nth-child(3) {
      padding-right: 0;
      margin-left: 8px;
      transition: all 0.3s ease-in-out;
    }
  }
  @media (max-width: 1080px) {
    padding: 0 24px;
  }
  @media (max-width: 750px) {
    background: rgba(0, 0, 0, 0.7);
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Background = styled(BackgroundImage)`
  position: relative;
  width: 90%;
  margin: 0 auto 40px auto;
  display: flex;
  justify-content: space-between;
`
const Container = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  display: grid;
  align-items: center;
  @media (max-width: 750px) {
    padding-left: 0;
    justify-content: center;
  }
  @media (max-width: 600px) {
    padding: 0 8px;
    height: 300px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

const ArrowLeft = styled(BsChevronLeft)`
  position: absolute;
  top: 50%;
  left: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  font-size: 40px;
  justify-self: center;
  :hover {
    left: 0;
  }
  @media (max-width: 660px) {
    display: none;
  }
`
const ArrowRight = styled(BsChevronRight)`
  position: absolute;
  top: 50%;
  right: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  font-size: 40px;
  justify-self: center;
  :hover {
    right: 0;
  }
  @media (max-width: 660px) {
    display: none;
  }
`

const ImageNavContainer = styled.div`
  display: flex;
  padding: 40px 0 0 32px;
  @media (min-width: 660px) {
    display: none;
  }
`
const ImageNav = styled.div<{ active: boolean }>`
  border-radius: 20px;
  height: 3px;
  background: white;
  width: 64px;
  margin-right: 8px;
  cursor: pointer;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  transition: all 0.3s ease-in-out;
  :hover {
    opacity: 1;
  }
`
