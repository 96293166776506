import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Reveal from "react-awesome-reveal"
import { BsArrowRightShort } from "react-icons/bs"

import revealAnimation from "../../../styles/animations/revealAnimation"

const GalleryItem = ({
  image,
  text,
  item_type,
  title,
  link,
  link_label,
  area,
}) => {
  return (
    <StyledLink to={link.url} area={area} type={item_type}>
      <Reveal keyframes={revealAnimation} triggerOnce>
        <Container fluid={image.fluid} area={area} type={item_type}>
          <Overlay>
            <Content type={item_type} onMouseOver={e => e.stopPropagation()}>
              <Title dangerouslySetInnerHTML={{ __html: title.html }} />
              <Text>{text.text}</Text>
              <ButtonContainer>
                <Button>{link_label}</Button>
                <Icon />
              </ButtonContainer>
            </Content>
          </Overlay>
        </Container>
      </Reveal>
    </StyledLink>
  )
}

export default GalleryItem

const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  transition: background 500ms ease-in-out;
  display: grid;

  align-content: center;
  :hover {
    background: rgba(0, 0, 0, 0.7);
    transition: background 0.5s ease-out;
    > div div:last-child {
      padding-right: 0;
      margin-left: 8px;
      transition: all 0.3s ease-in-out;
    }
  }
  @media (max-width: 750px) {
    background: rgba(0, 0, 0, 0.7);
  }
`

const StyledLink = styled(Link)<{
  area: number
  type: string
}>`
  position: relative;
  color: var(--primary-white);
  text-decoration: none;
  margin: 0 4px 0 0;
  grid-column-start: ${({ type }) => (type === "full" ? 1 : "initial")};
  grid-column-end: ${({ type }) => (type === "full" ? 3 : "initial")};
  justify-content: ${({ type }) =>
    type === "full" ? "flex-end" : "flex-start"};
  text-align: ${({ type }) => (type === "full" ? "right" : "initial")};
  @media (max-width: 1050px) {
    align-items: center;
    //padding: 0 80px;
    grid-column-start: 1;
    grid-column-end: initial;
    justify-content: ${({ type }) =>
      type === "full" ? "flex-end" : "flex-start"};
    text-align: ${({ type }) => (type === "full" ? "right" : "initial")};
  }
  @media (max-width: 600px) {
    height: 300px;
  }
`

const Container = styled(BackgroundImage)<{
  area: number
  type: string
}>`
  cursor: pointer;
  display: grid;
  align-items: center;
  height: 400px;
  @media (max-width: 600px) {
    padding: 0 8px;
    height: 300px;
  }
`
const Content = styled.div<{ type: string }>`
  display: grid;
  justify-items: ${({ type }) => (type === "full" ? "end" : "initial")};
  padding: 0 40px;
  @media (max-width: 1050px) {
    padding: 0 24px;
  }
  @media (max-width: 600px) {
    justify-items: center;
    text-align: center;
  }
`
export const Title = styled.div`
  p {
    color: var(--primary-white);
    font-size: 3.2rem;
    max-width: 500px;
    font-family: "Canela Light", sans-serif;
    @media (max-width: 600px) {
      font-size: 30px;
      padding-bottom: 16px;
    }
  }
`
export const Text = styled.p`
  color: var(--primary-white);
  max-width: 300px;
`
export const ButtonContainer = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 16px;
  padding-right: 8px;
  transition: all 0.3s ease-in-out;
  :hover {
    padding-right: 0;
    margin-left: 8px;
    transition: all 0.3s ease-in-out;
  }
`
export const Button = styled.span`
  color: var(--primary-white);
  text-transform: uppercase;
  text-decoration: none;
`

export const Icon = styled(BsArrowRightShort)`
  color: white;
  font-size: 24px;
  position: relative;
  top: 1px;
`
